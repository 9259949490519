import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

import backgroundNoise from "../../static/noise.webp"

const noiseKeyFrames = keyframes`
  0% {
    transform:translateX(0,0)
  }
  10% {
    transform:translate(-100px,100px)
  }
  20% {
    transform:translate(150px,-100px)
  }
  30% {
    transform:translate(-100px,100px)
  }
  40% {
    transform:translate(100px,-150px)
  }
  50% {
    transform:translate(-100px,200px)
  }
  60% {
    transform:translate(-200px,-100px)
  }
  70% {
    transform:translateY(50px,100px)
  }
  80% {
    transform:translate(100px,-150px)
  }
  90% {
    transform:translate(0,200px)
  }
  100% {
    transform:translate(-100px,100px)
  }
 `

const NoiseLayer = styled.div`
  position: absolute;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url(${backgroundNoise}) 0 0;
  background-size: auto;
  opacity: ${props => props.opacity};
  animation: ${noiseKeyFrames} 1s steps(8, end) infinite both;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
`

export default function Noise(props) {
  var opacity = props.opacity ?? 1
  return <NoiseLayer opacity={opacity} />
}
